import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[3]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[8]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[380px] pb-[100px] md:pb-[150px] md:pt-[400px]  ">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-[100%]">
              <h1 className="text-white text-center">
                {rpdata?.dbSlogan?.[4].slogan}
              </h1>
              <p className="text-white text-center">{rpdata?.dbValues?.[1].description}</p>
              <div className="flex justify-center">
                <ButtonContent />
              </div>
            </div>
          
          </div>

        </div>
      </div>
    </>
  );
}

export default HeroSection;
